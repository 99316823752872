import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import NotFoundPage from '../components/NotFoundPage';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import MessageUs from '../components/MessageUs';

const ErrorPage = () => (
  <Layout>
    <Navigation headerBackgroundColor="light" />
    <NotFoundPage />
    <MessageUs />
    <Footer noFooterCard={true} isLegacy={false} />
  </Layout>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="404: Page Not Found | Stormotion"
    description="Sorry, this page doesn't exist."
  />
);

export default React.memo(ErrorPage);
