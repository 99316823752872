import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import Text from '../Text';
import './style.scss';
import Link from '../Link';
import { keys } from 'ramda';

const pages = {
  Home: '/',
  Portfolio: '/portfolio',
  Blog: '/blog',
};

const NotFoundPage = () => {
  const { t } = useTranslation();

  const Pages = useMemo(
    () =>
      keys(pages).map((pageName) => (
        <Link href={pages[pageName]} key={pageName}>
          <Text type="label" className="not-found__pages-item">
            {pageName}
          </Text>
        </Link>
      )),
    []
  );

  return (
    <div className="not-found">
      <Text type="h1" weight="bold" className="not-found__title">
        {t('404_title')}
      </Text>
      <Text type="h2" className="not-found__description">
        {t('404_description')}
      </Text>
      <Text type="label" className="not-found__redirect">
        {t('404_redirect')}
      </Text>
      <div className="not-found__pages">{Pages}</div>
    </div>
  );
};

export default React.memo(NotFoundPage);
